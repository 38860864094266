<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <a-card>
                <a-row type="flex" :gutter="[8, 32]">
                    <a-col>
                        <span>入职时间：</span>
                        <a-range-picker @change="onDateChange" v-model="DateTime" style="width: 240px;" />
                    </a-col>
                    <a-col>
                        <a-input-search
                            name="filterText"
                            :placeholder="l('SearchWithThreeDot')"
                            @search="searchChange"
                            v-model="filterText"
                        />
                    </a-col>
                    <a-col>
                        <a-button :type="'primary'" @click="exportToExcel()" v-if="isGranted('hit_time_excel')">
                            <a-icon type="download" />
                            导出
                        </a-button>
                    </a-col>
                </a-row>
                <a-row type="flex" :gutter="[8, 32]">
                    <a-col>
                        <a-table
                            class="list-table"
                            :pagination="false"
                            :columns="columns"
                            :rowKey="(tableDatas) => tableDatas.processId"
                            :scroll="{ x: 1600, y: scroll_y }"
                            :dataSource="tableData"
                        >
                        </a-table>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-pagination
                            class="pagination"
                            :total="totalItems"
                            v-model="pageNumber"
                            showSizeChanger
                            showQuickJumper
                            :showTotal="showTotalFun"
                            @change="onChange"
                            @showSizeChange="showSizeChange"
                        />
                    </a-col>
                </a-row>
            </a-card>
        </a-spin>
    </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { AppComponentBase } from '../../../../shared/component-base';
import moment from 'moment';
import { CustomizeReportServiceServiceProxy } from '../../../../shared/service-proxies';
import { Dic, fileDownloadService } from '../../../../shared/utils';

export default {
    name: 'employeeconfirmation',
    mixins: [AppComponentBase],
    data() {
        return {
            zh_CN,
            //表格上方间隔
            defaultTop: 30,
            //loading:false,
            // 总数
            totalItems: 0,
            // 当前页码
            pageNumber: 1,
            // 共多少页
            totalPages: 1,
            // 条数显示范围
            pagerange: [1, 1],
            filterText: undefined,
            // 显示条数
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            request: { sorting: '', maxResultCount: 10, skipCount: 0 },
            columns: [
                {
                    title: this.l('工号'),
                    dataIndex: 'jobNumber',
                    ellipsis: true,
                    sorter: false,
                    align: 'left',
                    width: 100,
                    scopedSlots: { customRender: 'jobNumber' },
                },
                {
                    title: this.l('姓名'),
                    dataIndex: 'realName',
                    ellipsis: true,
                    sorter: false,
                    align: 'center',
                    width: 100,
                    scopedSlots: { customRender: 'realName' },
                },

                {
                    title: this.l('部门'),
                    dataIndex: 'departmentName',
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'departmentName' },
                    width: 120,
                },
                {
                    title: this.l('岗位'),
                    dataIndex: 'orgPostName',
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'orgPostName' },
                    width: 100,
                },
                {
                    title: this.l('学历'),
                    dataIndex: 'educationsName',
                    ellipsis: true,
                    align: 'center',
                    width: 100,
                    scopedSlots: { customRender: 'educationsName' },
                },
                {
                    title: this.l('性别'),
                    dataIndex: 'genderName',
                    ellipsis: true,
                    align: 'center',
                    width: 100,
                    scopedSlots: { customRender: 'genderName' },
                },
                {
                    title: this.l('入职日期'),
                    dataIndex: 'hireDate',
                    ellipsis: true,
                    sorter: false,
                    align: 'center',
                    width: 100,
                    scopedSlots: { customRender: 'hireDate' },
                },
                {
                    title: this.l('转正日期'),
                    dataIndex: 'regularDate',
                    ellipsis: true,
                    align: 'center',
                    width: 150,
                    scopedSlots: { customRender: 'regularDate' },
                },
                {
                    title: this.l('转正前薪资'),
                    dataIndex: 'formalFrontSalary',
                    ellipsis: true,
                    align: 'center',
                    width: 150,
                    scopedSlots: { customRender: 'formalFrontSalary' },
                },
                {
                    title: this.l('转正后薪资'),
                    dataIndex: 'formalAfterSalary',
                    ellipsis: true,
                    align: 'center',
                    width: 130,
                    scopedSlots: { customRender: 'formalAfterSalary' },
                },
                {
                    title: this.l('调薪幅度'),
                    dataIndex: 'changeSalaryProp',
                    ellipsis: true,
                    align: 'center',
                    width: 130,
                    scopedSlots: { customRender: 'changeSalaryProp' },
                },
            ],
            tableData: [],
            emquit: true,
            DateTime: [],
            StartTime: null,
            EndTime: null,
            _fileDownloadService: '',
        };
    },

    created() {
        this._CustomizeReportServiceServiceProxy = new CustomizeReportServiceServiceProxy(this.$apiUrl, this.$api);
        this._fileDownloadService = fileDownloadService;
    },

    async mounted() {
        this.getData();
    },
    watch: {
        CompanyId: function(value) {
            this.getData();
        },
    },

    methods: {
        searchChange() {
            this.getData();
        },
        getData() {
            this.loading = true;
            this._CustomizeReportServiceServiceProxy
                .getEmployeeConfirmation(
                    this.StartTime ? moment(this.StartTime) : undefined,
                    this.EndTime ? moment(this.EndTime) : undefined,
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.tableData = res.items;
                    this.totalItems = res.totalCount;
                    this.tableData.map((item) => {
                        item.hireDate = item.hireDate ? moment(item.hireDate).format('YYYY-MM-DD') : ' ';
                        item.regularDate = item.regularDate ? moment(item.regularDate).format('YYYY-MM-DD') : ' ';
                    });
                    this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                });
        },

        /**
         * 导出为excel
         */
        exportToExcel() {
            this.loading = true;
            this._CustomizeReportServiceServiceProxy
                .getEmployeeConfirmationExport(
                    this.StartTime ? moment(this.StartTime) : undefined,
                    this.EndTime ? moment(this.EndTime) : undefined,
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(() => {
                    this.loading = false;
                })
                .then((result) => {
                    this._fileDownloadService.downloadTempFile(result);
                });
        },

        /**
             /**
             * 分页事件
             */
        showTotalFun() {
            return this.l(
                'GridFooterDisplayText',
                this.pageNumber,
                this.totalPages,
                this.totalItems,
                this.pagerange[0],
                this.pagerange[1]
            );
        },
        /**
         * 分页
         */
        onChange(page, pageSize) {
            this.pageNumber = page;
            this.request.skipCount = (page - 1) * this.request.maxResultCount;
            this.getData();
        },
        showSizeChange(current, size) {
            this.pageNumber = 1;
            this.request.skipCount = 0;
            this.request.maxResultCount = size;
            this.getData();
        },
        onDateChange(e) {
            console.info(e);
            if (e.length > 0) {
                this.StartTime = e[0];
                this.EndTime = e[1];
                this.monthData = moment(this.EndTime).diff(moment(this.StartTime), 'months');
            } else {
                this.StartTime = null;
                this.EndTime = null;
            }
            this.request.skipCount = 0;
            this.pageNumber = 1;
            this.getData();
        },
    },
};
</script>

<style scoped>
.pagination {
    margin: 10px auto;
    text-align: right;
}
</style>
